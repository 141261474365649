<mat-dialog-content id="refund-request-modal">
    <!-- START: DOWNLOAD -->
    <section id="refund-request" class="w-100">
        <div id="refund-request__modal" class="container-fluid">
            <div class="refund-logo d-flex justify-content-center align-items-center">
                <app-image-by-brand imageName="refundRequest.svg"></app-image-by-brand>
            </div>
            <div class="row header">
                <!-- START: HEADER -->
                <div class="text-center">
                    <h4>Refund request</h4>
                </div>
                <!-- END: HEADER -->
            </div>
            <div class="sub-header">
                <div class="reference-number text-center">
                    <h6 class="mb-0">Payment Reference #</h6>
                    <p class="mb-0">{{ vendorInvoiceNumber | formatPaymentReference: currentPayment.id.toString() }}</p>
                </div>
            </div>
            <div class="sub-header mb-4" *ngIf="activeProfileView === profileTypes.FORWARDER ">
                <div class="refund-fee">
                    <div class="mb-0 d-flex">
                        <p class="title"><mat-icon class="icon-display">info</mat-icon> &nbsp; Refund fee:</p>
                        <div class="info px-3">
                            This facility may charge a refund fee.
                            <strong>CargoSprint never charges a refund fee</strong>
                        </div>
                    </div>
                </div>
            </div>
            <form class="row" [formGroup]="refundForm">
                <div class="col-12">
                    <div class="form-group">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Amount</mat-label>
                            <input
                                matInput
                                type="text"
                                id="amount"
                                name="amount"
                                formControlName="amount"
                                autocomplete="off"
                                placeholder="Amount"
                                data-cy="amount"
                                [errorStateMatcher]="matcher"
                                [allowNegativeNumbers]="false"
                                mask="separator.2"
                                thousandSeparator=""
                                prefix="$"
                                decimalMarker="."
                            />
                            <mat-error *ngIf="refundForm.controls['amount'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Amount is required
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['amount'].hasError('invalid')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Refund amount cannot be greater than
                                the original amount
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="d-block mb-2" appearance="outline">
                            <mat-label>Notification email</mat-label>
                            <input
                                matInput
                                type="email"
                                id="notificationEmail"
                                name="notificationEmail"
                                data-cy="notificationEmail"
                                formControlName="notificationEmail"
                                placeholder="Enter your notification email"
                                [errorStateMatcher]="matcher"
                                class="ps-1"
                            />
                            <mat-error *ngIf="refundForm.controls['notificationEmail'].hasError('pattern')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The email address was
                                malformed! Please try again
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['notificationEmail'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Notification email is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group">
                        <mat-form-field class="d-block mb-2" appearance="outline">
                            <mat-label>Refund reason</mat-label>
                            <mat-select
                                id="refundReason"
                                name="refundReason"
                                formControlName="refundReason"
                                data-cy="refundReason"
                                [errorStateMatcher]="matcher"
                                (selectionChange)="resetCustomerNotes($event)"
                            >
                                <mat-option *ngFor="let reason of refundReasons" [value]="reason">
                                    {{reason}}
                                </mat-option>
                            </mat-select>
                            <mat-error *ngIf="refundForm.controls['refundReason'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Refund reason is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group" *ngIf="refundReason.value === 'Other'">
                        <mat-form-field class="d-block" appearance="outline">
                            <mat-label>Custom reason</mat-label>
                            <textarea
                                matInput
                                id="customerNotes"
                                name="customerNotes"
                                formControlName="customerNotes"
                                autocomplete="off"
                                placeholder="Custom reason"
                                data-cy="customerNotes"
                                [errorStateMatcher]="matcher"
                            ></textarea>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('maxlength')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Custom reason cannot be greater than
                                the max length of 255
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('minlength')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Minimum of 5 symbols
                            </mat-error>
                            <mat-error *ngIf="refundForm.controls['customerNotes'].hasError('required')">
                                <i class="bi bi-exclamation-triangle-fill me-2"></i>Custom reason is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                </div>
            </form>
            <!-- START: BUTTON ACTIONS -->
            <div class="row">
                <div class="col-12">
                    <div id="download__actions" mat-dialog-actions class="pb-4 text-end d-block pt-1 pt-md-3">
                        <button
                            mat-button
                            type="button"
                            (click)="closeDialog()"
                            class="btn__sprintpay btn__sprintpay--link p-0 m-0 border-0 text-uppercase text-center"
                        >
                            Cancel
                        </button>
                        <button
                            mat-button
                            type="button"
                            (click)="refund()"
                            class="btn__sprintpay btn__sprintpay--green p-0 m-0 border-0 rounded-0 text-uppercase text-center"
                            [disabled]="refundForm.invalid"
                        >
                            Send
                        </button>
                    </div>
                </div>
            </div>
            <!-- END: BUTTON ACTIONS -->
        </div>
    </section>
    <!-- END: DOWNLOAD -->
</mat-dialog-content>
