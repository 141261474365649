import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

import { PaymentHistoryRoutingModule } from './payment-history-routing.module';

// COMPONENTS

import { PaymentHistoryComponent } from './payment-history.component';

// MODULES

import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { RefundService } from 'src/app/services/refund.service';
import { RefundAPIService } from 'src/app/services/requests/refund-api.service';
import { SharedModule } from '../../../../shared/shared.module';
import { PaymentDetailModule } from './payment-detail/payment-detail.module';

@NgModule({
    declarations: [PaymentHistoryComponent],
    imports: [
        CommonModule,
        PaymentHistoryRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        PaymentDetailModule,
    ],
    providers: [FormatPaymentReferencePipe, RefundAPIService, RefundService, CurrencyPipe],
})
export class PaymentHistoryModule {
    constructor() {}
}
