import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Subject, take, throwError } from 'rxjs';
import { debounceTime, distinctUntilChanged, finalize } from 'rxjs/operators';
import { Facility } from 'src/app/models/payments/facility.model';
import { FacilityService } from 'src/app/services/facility.service';
import { NewPaymentService } from 'src/app/services/new-payment.service';
import { FacilityAPIService } from 'src/app/services/requests/facility-api.service';
import { SummaryValidationService } from 'src/app/services/summary-validation.service';
import { CartService } from 'src/app/services/utils/cart.service';
import { CustomerService } from 'src/app/services/utils/customer-handler.service';
import { PaymentFluxService } from 'src/app/services/utils/payment-flux.service';
import { StorageService } from 'src/app/services/utils/storage.service';
import { CustomValidators } from 'src/app/utils/custom-validators';
import Swal from 'sweetalert2';
import { BreadcrumbService } from 'xng-breadcrumb';

@Component({
    selector: 'app-facility-search',
    templateUrl: './facility-search.component.html',
    animations: [
        trigger('fadeIn', [
            transition(':enter', [style({ opacity: '0' }), animate('.3s ease-out', style({ opacity: '1' }))]),
        ]),
    ],
})
export class FacilitySearchComponent implements OnInit, OnDestroy {
    searchForm!: FormGroup;
    facilities: Facility[];
    noInformation: boolean;
    notRecordsFound: boolean;
    frequentFacilities: boolean;
    pagesToLoadMore: number;
    querySize: number;
    showMoreRecordsButton: boolean;
    customer: any;
    facilityName: string;
    isSearching: boolean;
    private _notes: string[];
    private _searchTermChanged: Subject<string>;
    private readonly FREQUENT_FACILITIES_COUNT: number = 6;
    private readonly INITIAL_PAGE_SIZE: number = 10;

    // numberOfFacilities: number;
    // allFacilities: any[];
    // showFilters: boolean;
    // filterAirport: boolean;
    // filterFedex: boolean;
    // filterMessenger: boolean;
    // filterElectronic: boolean;
    // valueFilter: string;
    // debouncedValueFilter: string;
    // private _filterDecouncer: Subject<string> = new Subject();

    constructor(
        private _formBuilder: FormBuilder,
        private _paymentFluxService: PaymentFluxService,
        private _customerService: CustomerService,
        private _storageService: StorageService,
        private _breadcrumbService: BreadcrumbService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _summaryValidationService: SummaryValidationService,
        private _newPaymentService: NewPaymentService,
        private _facilityService: FacilityService,
        private _facilityAPIService: FacilityAPIService,
        private _cartService: CartService,
        private _router: Router
    ) {
        this._breadcrumbService.set('@new-payment', 'New payment');
        this.pagesToLoadMore = 0;
        this.notRecordsFound = false;
        this.frequentFacilities = false;
        this.facilities = [];
        this._notes = [];
        this.noInformation = false;
        this.querySize = this.INITIAL_PAGE_SIZE;
        this.showMoreRecordsButton = false;
        this.customer = this._customerService.getCustomer();
        this.facilityName = '';
        this.isSearching = false;
        this._searchTermChanged = new Subject<string>();

        // this.numberOfFacilities = 0;
        // this.allFacilities = [];
        // this.showFilters = false;
        // this.filterAirport = false;
        // this.filterFedex = false;
        // this.filterMessenger = false;
        // this.filterElectronic = false;
        // this.valueFilter = '';
        // this.debouncedValueFilter = this.valueFilter;
    }

    ngOnInit(): void {
        this._setFormBuilder();
        this._setupSeachDebouncer();
        // this.setupFilterDebouncer();
        if (!this._customerService.isGuest()) {
            this._getFrequentFacilities();
        } else {
            this.noInformation = true;
        }
    }

    ngOnDestroy(): void {
        this._searchTermChanged.complete();
        // this._filterDecouncer.complete();
    }

    private _setFormBuilder(): void {
        this.searchForm = this._formBuilder.group({
            search: new FormControl<string | null>('', [CustomValidators.preventHTMLContent()]),
        });
    }

    /**
     * @method _setupSeachDebouncer()
     * @description Blocks search action
     */

    private _setupSeachDebouncer(): void {
        this._searchTermChanged.pipe(debounceTime(300), distinctUntilChanged()).subscribe((searchString) => {
            this._searchFacilities(searchString);
        });
    }

    /**
     * @method _getFrequentFacilities()
     * @description Get most frequent facilities
     */

    private _getFrequentFacilities(): void {
        this._ngxSpinnerService.show();

        this._facilityAPIService
            .getFrequentFacilities(this.FREQUENT_FACILITIES_COUNT)
            .pipe(
                take(1),
                finalize(() => {
                    this._ngxSpinnerService.hide();
                })
            )
            .subscribe({
                next: (facilities) => {
                    this.noInformation = facilities.length <= 0;
                    this.frequentFacilities = facilities.length > 0;
                    this.facilities = facilities.map((facility) => ({
                        ...facility,
                        address: this._getAddress(facility),
                    }));
                    // this.numberOfFacilities = this.facilities.length;
                },
                error: (error: Error) => {
                    throwError(() => error);
                },
            });
    }

    /**
     * @method _getAddress()
     * @param (facility: Facility)
     * @description Builds facility address string
     */

    private _getAddress(facility: Facility): string {
        let address = facility.address ? facility.address : '';
        address += !facility.city ? '' : address ? ', ' + facility.city : facility.city;
        address += !facility.state ? '' : address ? ', ' + facility.state : facility.state;
        address += !facility.zip ? '' : address ? ', ' + facility.zip : facility.zip;
        address = address ? address : 'Address not available';
        return address;
    }

    get getSearchInput(): AbstractControl<string> | null {
        return this.searchForm.get('search');
    }

    onSubmit(): void {
        if (this.getSearchInput) {
            this._searchFacilities(this.getSearchInput.value);
        }
    }

    /**
     * @method searchOnClick()
     * @description Call search facilities action by clicking search icon
     */

    searchOnClick(): void {
        if (this.isSearching || !this.getSearchInput) {
            return;
        }
        this._searchFacilities(this.getSearchInput.value);
    }

    clearSearch(): void {
        this.searchForm.get('search')?.setValue('');
        this._searchFacilities('');
    }

    /**
     * @method _searchFacilities()
     * @param (facilityName: string)
     * @description Search facilities
     */

    private _searchFacilities(facilityName: string): void {
        this.facilityName = facilityName;
        this.facilities = [];
        this.frequentFacilities = false;

        // if (this.showFilters) {
        // this.initFilterFacilities();
        // }

        if (facilityName === '') {
            // this.numberOfFacilities = 0;
            this.notRecordsFound = false;
            this.showMoreRecordsButton = false;
            this._getFrequentFacilities();
            this._storageService.removeElement('currentPayment', 'session');
        } else {
            this.isSearching = true;
            this._ngxSpinnerService.show();

            this._facilityAPIService
                .searchFacilities(facilityName, 0, this.querySize)
                .pipe(
                    take(1),
                    finalize(() => {
                        this.isSearching = false;
                        this._ngxSpinnerService.hide();
                    })
                )
                .subscribe({
                    next: (result) => {
                        if (result.content.length > 0) {
                            this.noInformation = false;
                            this.pagesToLoadMore = result.pageable?.pageNumber || 0;
                            this.facilities = JSON.parse(JSON.stringify(result.content, null, 2));
                            this.showMoreRecordsButton = result.totalPages !== undefined && result.totalPages > 1;
                            this.frequentFacilities = false;
                            this.querySize = result.pageable?.pageSize || this.INITIAL_PAGE_SIZE;
                        } else {
                            this.showMoreRecordsButton = false;
                        }
                        // this.numberOfFacilities = this.facilities.length;
                        this.notRecordsFound = this.facilities.length === 0;
                    },
                    error: (error: Error) => {
                        throwError(() => error);
                    },
                });
        }
    }

    /**
     * @method loadMoreFacilities()
     * @description Load next page with facilities
     */

    loadMoreFacilities(): void {
        this.pagesToLoadMore++;
        this._ngxSpinnerService.show();

        this._facilityAPIService
            .searchFacilities(this.facilityName, this.pagesToLoadMore, this.querySize)
            .pipe(
                take(1),
                finalize(() => {
                    this._ngxSpinnerService.hide();
                })
            )
            .subscribe({
                next: (result) => {
                    if (result.content.length > 0) {
                        this.facilities = this.facilities.concat(result.content);
                        this.frequentFacilities = false;
                        this.querySize = result.pageable?.pageSize || this.INITIAL_PAGE_SIZE;
                        // this.numberOfFacilities = this.facilities.length;
                        this.notRecordsFound = this.facilities.length === 0;
                    }
                    if (result.last) {
                        this.showMoreRecordsButton = false;
                    }
                },
                error: (error: Error) => {
                    throwError(() => error);
                },
            });
    }

    /**
     * @method selectFacility()
     * @param (facility: Facility)
     * @description Select facility to continue creation of payment
     */

    selectFacility(facility: Facility): void {
        this._facilityAPIService.getFacilityDetailsRequest(facility.id).subscribe((facilityDetail: any) => {
            const facilitySelected: Facility = this._facilityService.facilityValidation({
                ...facility,
                ...facilityDetail,
            });
            this._summaryValidationService
                .addToCartValidate('paymentRequest', facilitySelected)
                .pipe(take(1))
                .subscribe({
                    next: (result: any) => {
                        if (result.continue) {
                            this._paymentFluxService.removeCurrentPayment();
                            this._paymentFluxService.removeDynamicFields();
                            this._paymentFluxService.removeDynamicValues();
                            this._paymentFluxService.setCurrentPayment({ facility: facilitySelected });
                            this._swalExternalUrlNotes(facilitySelected);
                        } else {
                            Swal.fire({
                                html: '<h5>Hey, psst psst...</h5>' + '<p>' + result.message + '</p>',
                                icon: 'info',
                                showCancelButton: true,
                                confirmButtonText: 'OK',
                                cancelButtonText: 'Cancel',
                                allowOutsideClick: false,
                                reverseButtons: true,
                            }).then((result): void => {
                                if (result.isConfirmed) {
                                    this._summaryValidationService.deleteCart();
                                    this._paymentFluxService.setCurrentPayment({ facility: facilitySelected });
                                    this._swalExternalUrlNotes(facilitySelected);
                                }
                            });
                        }
                    },
                    error: (error: Error) => {
                        throwError(() => error);
                    },
                });
        });
    }

    reviewFacilityInfo(facility: Facility): void {
        if (facility.externalLookup) {
            this._cartService.reset3rdPartyItems();
            this._cartService.resetSubmittedItems();
            this._storageService.removeElement('HBL', 'session');
            this._paymentFluxService.setCurrentPayment({ facility: facility });
            this._router.navigate(['/admin/facilityPayments/newPayment/codafacility']);
        } else {
            this._newPaymentService.swalFacilitySelect(facility);
        }
    }

    /**
     * @method _swalExternalUrlNotes()
     * @param (facility: Facility)
     * @description Display the Swal to review the external url and notes
     */

    private _swalExternalUrlNotes(facility: Facility): void {
        let notify = facility.facilityPaymentDeliveryNotification;
        const hasNotify = !!notify;

        if (facility.payUrl && facility.awbLookupData == null) {
            Swal.fire({
                html: `<h5 class="mt-3" style='text-align: center; color: #032747;'>Please wait...Hey, psst psst...</h5>
               <div style='padding: 1rem 1rem 0 1rem; text-align: center;'>
                  In order to complete a payment to <strong> ${facility.name} </strong> you'll be redirected to its site
               </div>`,
                icon: 'info',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Redirect',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
                reverseButtons: true,
            }).then((result): void => {
                if (result.isConfirmed) {
                    window.open(facility.payUrl, '_blank');
                }
            });
        } else if (facility.awbLookupData) {
            this.reviewFacilityInfo(facility);
        } else if (facility.notesForClients) {
            facility.notesForClients = Array.isArray(facility.notesForClients)
                ? JSON.stringify(facility.notesForClients)
                : facility.notesForClients;
            this._notes =
                typeof facility.notesForClients === 'string'
                    ? JSON.parse(facility.notesForClients)
                    : facility.notesForClients;
            let $listItem = '';
            for (let index = 0; index < this._notes.length; index++) {
                if (facility.isWFS && facility.airportCode && facility.airportCode.toLowerCase() === 'lax') {
                    this._notes[index] = this._facilityService.convertUrl(this._notes[index]);
                }
                $listItem += '<li style="font-weight: 400">' + this._notes[index] + '</li>';
            }
            Swal.fire({
                html: `<h5 class="mt-3" style='text-align: center; color: #032747;'>Important Notice</h5>
                ${hasNotify ? `<h6>${notify}</h6>` : ''} 
                <ul style="list-style: none; text-align:left">
                  ${$listItem}
                </ul>`,
                icon: 'info',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
                reverseButtons: true,
            }).then((result): void => {
                if (result.isConfirmed) {
                    this.reviewFacilityInfo(facility);
                }
            });
        } else if (hasNotify) {
            Swal.fire({
                html: `<h5 class="mt-3" style='text-align: center; color: #032747;'>Important Notice</h5>
                <h6>${notify}</h6>`,
                icon: 'info',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonText: 'Continue',
                cancelButtonText: 'Cancel',
                allowOutsideClick: false,
                reverseButtons: true,
            }).then((result): void => {
                if (result.isConfirmed) {
                    this.reviewFacilityInfo(facility);
                }
            });
        } else {
            this.reviewFacilityInfo(facility);
        }
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: Facility)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: Facility): string {
        return item.id;
    }

    // /**
    //  * @method setupFilterDebouncer()
    //  */
    // private setupFilterDebouncer(): void {
    //     this._filterDecouncer.pipe(debounceTime(300), distinctUntilChanged()).subscribe((event: any) => {
    //         this.debouncedValueFilter = event.target.value;

    //         this.filterFacilities();
    //     });
    // }

    // /**
    //  * @method getAllFacilities()
    //  * @param (totalElements: number)
    //  * @param (facility: string)
    //  * @description
    //  */

    // getAllFacilities(totalElements: number, facility: string) {
    //     this._restService
    //         .get(
    //             `${
    //                 this._facilitiesURL
    //             }/searchFacilities?searchTerms=${facility}&page=0&size=${totalElements}&date=${new Date().valueOf()}`,
    //             {}
    //         )
    //         .then((result: any) => {
    //             if (result.content.length > 0) {
    //                 this.allFacilities = JSON.parse(JSON.stringify(result.content, null, 2));
    //             }
    //         });
    // }

    // /**
    //  * @method initFilterFacilities()
    //  * @description initialize the filter controls
    //  */

    // initFilterFacilities() {
    //     this.filterAirport = false;
    //     this.filterFedex = false;
    //     this.filterMessenger = false;
    //     this.filterElectronic = false;
    //     this.showFilters = false;
    //     this.valueFilter = '';
    // }

    // /**
    //  * @method filterFacilities()
    //  * @description
    //  */

    // filterFacilities() {
    //     this.showMoreRecordsButton = false;
    //     let filteredFacilities: any[] = [];

    //     if (!this.debouncedValueFilter && !this.filterFedex && !this.filterMessenger && !this.filterElectronic) {
    //         this.searchFacilities(this.facilityName);
    //         return;
    //     }

    //     filteredFacilities = this.allFacilities.filter((item: any) => {
    //         const lowercaseAirportCode = item['airportCode']?.toLowerCase();
    //         const lowercaseDeliveryType = item['deliveryType']?.toLowerCase();

    //         if (this.debouncedValueFilter && lowercaseAirportCode === this.debouncedValueFilter.trim().toLowerCase()) {
    //             return true;
    //         }

    //         if (this.filterFedex && lowercaseDeliveryType === 'fedex') {
    //             return true;
    //         }

    //         if (this.filterMessenger && lowercaseDeliveryType === 'messenger') {
    //             return true;
    //         }

    //         if (this.filterElectronic && lowercaseDeliveryType === 'electronic') {
    //             return true;
    //         }

    //         return false;
    //     });

    //     this.facilities = filteredFacilities;
    //     this.numberOfFacilities = this.facilities.length;
    //     this.notRecordsFound = this.facilities.length === 0;
    // }

    // /**
    //  * @method filterByAirport()
    //  * @param (event: any)
    //  * @description
    //  */

    // filterByAirport(event: any) {
    //     this._filterDecouncer.next(event);
    // }
}
