import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BehaviorSubject, Observable, from, map, of } from 'rxjs';
import { RefundStatusDescription } from 'src/app/models/refunds/refunds';
import Swal, { SweetAlertResult } from 'sweetalert2';
import { HistoryContent } from '../models/refunds/refund-status-history';
import { CustomStatuses, PaymentRefund } from '../models/refunds/refunds';
import { PaymentLogs } from '../modules/refunds-requests/refunds-detail/model/refunds-detail.model';
import { RefundAPIService } from './requests/refund-api.service';
@Injectable()
export class RefundService {
    private paymentDetail: BehaviorSubject<PaymentRefund | null> = new BehaviorSubject<PaymentRefund | null>(null);

    constructor(
        private refundAPIService: RefundAPIService,
        private currencyPipe: CurrencyPipe
    ) {}

    setPaymentDetail(paymentDetail: PaymentRefund | null): void {
        this.paymentDetail.next(paymentDetail);
    }

    get instant_payment_detail(): PaymentRefund | null {
        return this.paymentDetail.value;
    }

    getPaymentDetail$(): Observable<PaymentRefund | null> {
        return this.paymentDetail.asObservable();
    }

    getModalNotes(): Observable<SweetAlertResult> {
        return from(
            Swal.fire({
                icon: 'info',
                title: 'NOTE REGARDING REFUND OF CARGOSPRINT HANDLING FEE:',
                html: `<div styles="font-weight: 400; font-size: 18px; line-height: 25px">
                Sometimes but not always CargoSprint refunds the original CargoSprint handling fee. In general
                <strong>if you request a refund by 3pm EST on the same day you placed the original payment request we
                    WILL refund the original CargoSprint handling fee.</strong>
                If actual funds have left our hands we do NOT refund the handling fee. Please refer to our
                <a href='https://cargosprint.com/legal/terms-of-use/sprintpay' target="_blank">
                    Terms of Use
                </a>
                for more details.
            
        </div>`,
                width: '35em',
                showCancelButton: true,
                iconColor: '#FACEA8',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonText: 'CONTINUE',
                cancelButtonText: 'CLOSE',
                reverseButtons: true,
                customClass: {
                    title: 'lh-35',
                },
            })
        );
    }

    private getNameByStatus(historyContent: HistoryContent): string {
        if (historyContent?.status === CustomStatuses.REQUESTED) {
            if (historyContent?.requestedBy?.firstName) {
                return `${historyContent?.requestedBy?.firstName || ''} ${historyContent?.requestedBy?.lastName || ''}`;
            }
        }

        if (historyContent?.lastModifiedBy?.firstName) {
            return `${historyContent?.lastModifiedBy?.firstName || ''} ${historyContent?.lastModifiedBy?.lastName || ''}`;
        }

        return '';
    }

    private getTotalRefundedAmount(historyContent: HistoryContent): number | null {
        if (historyContent?.status === CustomStatuses.FINALIZED) {
            return historyContent?.totalRefundedAmount || null;
        }

        return null;
    }

    getPaymentLogs(id: number | undefined): Observable<PaymentLogs[] | undefined> {
        if (!id) {
            return of([]);
        }

        return this.refundAPIService.getPaymentRefundHistory(id).pipe(
            map((refundHistoryLog) => {
                return refundHistoryLog.content?.map((historyContent: HistoryContent) => {
                    const requesterName = this.getNameByStatus(historyContent);
                    const refundFee = historyContent?.refundFee
                        ? this.currencyPipe.transform(historyContent?.refundFee)
                        : null;
                    const totalRefunded = this.getTotalRefundedAmount(historyContent);
                    const totalRefundedAmount = totalRefunded ? this.currencyPipe.transform(totalRefunded) : null;
                    const formatDate = 'MMM DD, YYYY, h:mm:ss a';

                    return {
                        date: moment(historyContent.lastModifiedDate).format(formatDate),
                        approval: requesterName ? `Requester: <strong>${requesterName}</strong>` : null,
                        event: `Refund reason: <strong>${historyContent.refundReason}</strong>`,
                        request: `Refund request amount: <strong>USD $${historyContent.requestedAmount}</strong>`,
                        flag: historyContent.status ? `${RefundStatusDescription[historyContent.status]}` : '',
                        refundFee: refundFee ? `Refund fee: <strong>${refundFee}</strong>` : null,
                        totalRefundedAmount: totalRefundedAmount
                            ? `Total refunded amount: <strong>${totalRefundedAmount}</strong>`
                            : null,
                    };
                });
            })
        );
    }
}
