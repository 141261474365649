<form [formGroup]="personalInformationForm" *ngIf="personalInformationForm">
    <!--START: First name -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>First name</mat-label>
            <input
                matInput
                type="text"
                id="firstName"
                name="firstName"
                data-cy="firstName"
                formControlName="firstName"
                placeholder="Enter your first name"
                [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="firstName.hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
            <mat-error *ngIf="firstName.hasError('minlength')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 3 characters.
            </mat-error>
            <mat-error *ngIf="firstName.hasError('pattern')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. We allow only alphanumeric characters.
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: First name -->
    <!--START: Last name -->
    <div class="form-group">
        <mat-form-field class="d-block" appearance="outline">
            <mat-label>Last name</mat-label>
            <input
                matInput
                type="text"
                id="lastName"
                name="lastName"
                data-cy="lastName"
                formControlName="lastName"
                placeholder="Enter your last name"
                [errorStateMatcher]="matcher"
            />
            <mat-error *ngIf="lastName.hasError('required')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
            </mat-error>
            <mat-error *ngIf="lastName.hasError('minlength')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 3 characters.
            </mat-error>
            <mat-error *ngIf="lastName.hasError('pattern')">
                <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. We allow only alphanumeric characters.
            </mat-error>
        </mat-form-field>
    </div>
    <!--END: Last name -->
    <!--START: Phone -->
    <div class="form-group phone-input">
        <lib-phone-input
            [isFormEnabled]="true"
            [blackListCountryCodes]="blackListedCountryCodes"
            [countryCode]="initialCountryCode"
            [phone]="initialPhone"
        ></lib-phone-input>
    </div>
    <!--END: Phone -->
    <!--START: Email -->
    <app-validate-email (eventOnChangeValidateEmail)="validateEmail($event)"></app-validate-email>
    <!--END: Email -->
</form>
