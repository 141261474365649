<!--START: Account form -->
<form class="row" [formGroup]="accountForm" (ngSubmit)="onSubmit()">
    <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <!--START: First name -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>First name</mat-label>
                <input
                    matInput
                    type="text"
                    id="firstName"
                    name="firstName"
                    formControlName="firstName"
                    autocomplete="off"
                    placeholder="Enter your first name"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['firstName'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['firstName'].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 3 characters.
                </mat-error>
                <mat-error *ngIf="accountForm.controls['firstName'].hasError('preventedHTMLStr')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags or URLs are not allowed
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: First name -->
        <!--START: Last name -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Last name</mat-label>
                <input
                    matInput
                    type="text"
                    id="lastName"
                    name="lastName"
                    formControlName="lastName"
                    autocomplete="off"
                    placeholder="Enter your last name"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['lastName'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['lastName'].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 3 characters.
                </mat-error>
                <mat-error *ngIf="accountForm.controls['lastName'].hasError('preventedHTMLStr')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags or URLs are not allowed
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Last name -->
        <!--START: Phone -->
        <lib-phone-input
            [isFormEnabled]="true"
            [blackListCountryCodes]="blackListedCountryCodes"
            [countryCode]="initialCountryCode"
            [phone]="initialPhone"
        ></lib-phone-input>
        <!--END: Phone -->
        <!--START: Username -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Username</mat-label>
                <input
                    matInput
                    type="text"
                    id="email"
                    name="email"
                    formControlName="email"
                    autocomplete="off"
                    placeholder="Enter your username"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['email'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['email'].hasError('email')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The email address was malformed! Please
                    try again
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Username -->
        <!--START: Notification email -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Notification email</mat-label>
                <input
                    matInput
                    type="text"
                    id="notificationEmail"
                    name="notificationEmail"
                    formControlName="notificationEmail"
                    autocomplete="off"
                    placeholder="Enter your notification email"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['notificationEmail'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['notificationEmail'].hasError('email')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. The email address was malformed! Please
                    try again
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Notification email -->
        <!--START: Street address -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Street address</mat-label>
                <input
                    matInput
                    type="text"
                    id="street"
                    name="street"
                    formControlName="street"
                    autocomplete="off"
                    placeholder="Enter your street address"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['street'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['street'].hasError('preventedHTMLStr')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags or URLs are not allowed
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Street address -->
        <!--START: Country -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Country</mat-label>
                <mat-select
                    id="country"
                    formControlName="country"
                    data-cy="country"
                    (selectionChange)="selectCountry($event.value)"
                    [errorStateMatcher]="matcher"
                >
                    <mat-option *ngFor="let country of countries; trackBy: trackBy" [value]="country.alpha3Code">
                        {{country.name}}
                    </mat-option>
                </mat-select>
                <mat-error *ngIf="accountForm.controls['country'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Country -->
        <!--START: State -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>State</mat-label>
                <input
                    matInput
                    mask="SS"
                    type="text"
                    maxlength="2"
                    id="state"
                    name="state"
                    formControlName="state"
                    autocomplete="off"
                    placeholder="Enter your state"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['state'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['state'].hasError('minlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Oops. Should have at least 2 characters
                </mat-error>
                <mat-error *ngIf="accountForm.controls['state'].hasError('maxlength')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Maximum of 2 characters
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: State -->
        <!--START: City -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>City</mat-label>
                <input
                    matInput
                    type="text"
                    id="city"
                    name="city"
                    formControlName="city"
                    autocomplete="off"
                    placeholder="Enter your city"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['city'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['city'].hasError('preventedHTMLStr')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags or URLs are not allowed
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: City -->
        <!--START: Zip code -->
        <div class="form-group">
            <mat-form-field class="d-block mb-2" appearance="outline">
                <mat-label>Zip code</mat-label>
                <input
                    matInput
                    [mask]="zipConfig.zipMask"
                    type="text"
                    id="zipCode"
                    name="zipCode"
                    formControlName="zipCode"
                    autocomplete="off"
                    placeholder="Enter your zip code"
                    [errorStateMatcher]="matcher"
                />
                <mat-error *ngIf="accountForm.controls['zipCode'].hasError('required')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>Required
                </mat-error>
                <mat-error *ngIf="accountForm.controls['zipCode'].hasError('preventedHTMLStr')">
                    <i class="bi bi-exclamation-triangle-fill me-2"></i>HTML tags or URLs are not allowed
                </mat-error>
            </mat-form-field>
        </div>
        <!--END: Zip code -->
    </div>
    <div class="d-flex">
        <!--START: Submit button -->
        <button
            mat-flat-button
            color="primary"
            type="submit"
            class="d-block mx-auto border-0 rounded-0 text-uppercase btn__sprintpay btn__sprintpay--green"
            [ngClass]="{'btn__sprintpay--greenDisabled': accountForm.invalid}"
        >
            Save account information
        </button>
        <!--END: Submit button -->
    </div>
</form>
<!--END: Account form -->
