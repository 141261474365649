import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Customer } from '@cargos/sprintpay-models';
import { PhoneInputComponent } from '@cargos/sprintpay-ui';
import { PhoneFormGroupType } from '@cargos/sprintpay-ui/lib/modules/phone/phone-input/models/phone-input-form';
import { NgxSpinnerService } from 'ngx-spinner';
import { blackListedCountryCodes, InitialCountries, regex } from 'src/app/utils/constants';
import { CustomMasks } from 'src/app/utils/custom-mask';
import { CustomValidators } from 'src/app/utils/custom-validators';
import Swal from 'sweetalert2';
import { environment } from '../../../../../environments/environment';
import { Countries as countries } from '../../../../models/utils/countries.model';
import { RestService } from '../../../../services/rest.service';
import { CustomerService } from '../../../../services/utils/customer-handler.service';
import { ErrorHandlerService } from '../../../../services/utils/error-handler.service';
import { ErrorMatcher } from '../../../../utils/errorMatcher';

@Component({
    selector: 'app-account',
    templateUrl: './account.component.html',
})
export class AccountComponent implements OnInit, AfterViewInit {
    accountForm!: FormGroup;
    countries!: countries[];
    matcher: ErrorMatcher;
    zipConfig: { zipCodeLength: number; zipMask: string };
    private readonly _authentication: string;
    public blackListedCountryCodes: string[] = blackListedCountryCodes;
    public initialCountryCode: string = '';
    public initialPhone: string = '';
    public accountInformation: Customer;
    public phoneForm!: PhoneFormGroupType;
    @ViewChild(PhoneInputComponent) PhoneInputComponent: PhoneInputComponent;
    constructor(
        private _formBuilder: FormBuilder,
        private _restService: RestService,
        private _ngxSpinnerService: NgxSpinnerService,
        private _errorHandlerService: ErrorHandlerService,
        private _customerService: CustomerService
    ) {
        this._authentication = environment.uris.method.authentication;
        this.matcher = new ErrorMatcher();
        this.zipConfig = {
            zipCodeLength: 5,
            zipMask: CustomMasks.iMaskZip,
        };
        this.countries = InitialCountries;
    }

    ngOnInit(): void {
        this._setFromBuilder();
    }
    ngAfterViewInit(): void {
        this.phoneForm = this.PhoneInputComponent.phoneForm;
    }
    /**
     * @method setFromBuilder()
     * @description Set the form requirements to be a valid submission
     */

    private _setFromBuilder(): void {
        this.accountInformation = this._customerService.getCustomer();
        this.zipConfig = CustomMasks.iMaskZipCodeValidation(this.accountInformation?.country);
        this.accountForm = this._formBuilder.group({
            firstName: new FormControl<any | null>(this.accountInformation.firstName, [
                Validators.required,
                Validators.minLength(3),
                CustomValidators.preventHTMLContent(),
            ]),
            lastName: new FormControl<any | null>(this.accountInformation.lastName, [
                Validators.required,
                Validators.minLength(3),
                CustomValidators.preventHTMLContent(),
            ]),
            email: new FormControl<any | null>({ value: this.accountInformation.email, disabled: true }, [
                Validators.required,
                Validators.email,
                Validators.pattern(regex.email),
            ]),
            notificationEmail: new FormControl<any | null>(this.accountInformation.notificationEmail, [
                Validators.required,
                Validators.email,
                Validators.pattern(regex.email),
            ]),
            street: new FormControl<any | null>(this.accountInformation.street, [
                Validators.required,
                CustomValidators.preventHTMLContent(),
            ]),
            country: new FormControl<any | null>(this.accountInformation.country, [Validators.required]),
            state: new FormControl<any | null>(this.accountInformation.state, [
                Validators.required,
                Validators.maxLength(2),
                Validators.minLength(2),
                Validators.pattern(regex.alpha),
            ]),
            city: new FormControl<any | null>(this.accountInformation.city, [
                Validators.required,
                CustomValidators.preventHTMLContent(),
            ]),
            zipCode: new FormControl<any | null>(this.accountInformation.zipCode, [
                Validators.required,
                CustomValidators.preventHTMLContent(),
            ]),
        });
        this.phone?.setValue(this.phoneInput?.value || '');
        this.initialCountryCode = this.accountInformation.countryCode || 'US';
        this.initialPhone = this.accountInformation.phone;
    }

    /**
     * @method getFirstName()
     * @description: Convenience getter for easy access to form fields
     */

    get getFirstName(): FormGroup {
        return this.accountForm.get('firstName') as FormGroup;
    }

    /**
     * @method getLastName()
     * @description: Convenience getter for easy access to form fields
     */

    get getLastName(): FormGroup {
        return this.accountForm.get('lastName') as FormGroup;
    }

    /**
     * @method getPhone()
     * @description: Convenience getter for easy access to form fields
     */

    get getPhone(): FormGroup {
        return this.accountForm.get('phone') as FormGroup;
    }

    /**
     * @method getEmail()
     * @description: Convenience getter for easy access to form fields
     */

    get getEmail(): FormGroup {
        return this.accountForm.get('email') as FormGroup;
    }

    /**
     * @method getNotificationEmail()
     * @description: Convenience getter for easy access to form fields
     */

    get getNotificationEmail(): FormGroup {
        return this.accountForm.get('notificationEmail') as FormGroup;
    }

    /**
     * @method getStreet()
     * @description: Convenience getter for easy access to form fields
     */

    get getStreet(): FormGroup {
        return this.accountForm.get('street') as FormGroup;
    }

    /**
     * @method getCountry()
     * @description: Convenience getter for easy access to form fields
     */

    get getCountry(): FormGroup {
        return this.accountForm.get('country') as FormGroup;
    }

    /**
     * @method getState()
     * @description: Convenience getter for easy access to form fields
     */

    get getState(): FormGroup {
        return this.accountForm.get('state') as FormGroup;
    }

    /**
     * @method getCity()
     * @description: Convenience getter for easy access to form fields
     */

    get getCity(): FormGroup {
        return this.accountForm.get('city') as FormGroup;
    }

    /**
     * @method getZipCode()
     * @description: Convenience getter for easy access to form fields
     */

    get getZipCode(): FormGroup {
        return this.accountForm.get('zipCode') as FormGroup;
    }

    /**
     * @method setFirstName()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setFirstName(event: any) {
        this.getFirstName!.setValue(event);
    }

    /**
     * @method setLastName()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setLastName(event: any) {
        this.getLastName!.setValue(event);
    }

    /**
     * @method setPhone()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setPhone(event: any) {
        this.getPhone!.setValue(event);
    }

    /**
     * @method setEmail()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setEmail(event: any) {
        this.getEmail!.setValue(event);
    }

    /**
     * @method setNotificationEmail()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setNotificationEmail(event: any) {
        this.getNotificationEmail!.setValue(event);
    }

    /**
     * @method setStreet()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setStreet(event: any) {
        this.getStreet!.setValue(event);
    }

    /**
     * @method setCountry()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setCountry(event: any) {
        this.getCountry!.setValue(event);
    }

    /**
     * @method setState()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setState(event: any) {
        this.getState!.setValue(event);
    }

    /**
     * @method setCity()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setCity(event: any) {
        this.getCity!.setValue(event);
    }

    /**
     * @method setSipCode()
     * @param (event: any)
     * @description Convenience setter for easy access to form fields
     */
    set setSipCode(event: any) {
        this.getZipCode!.setValue(event);
    }

    /**
     * @method selectCountry()
     * @param (event: any)
     * @description Mask the code based on country
     */

    selectCountry(code: string): void {
        const country: any = this.countries.find((item) => item.alpha3Code === code);
        this.zipConfig = CustomMasks.iMaskZipCodeValidation(country?.name);
        this.accountForm.get('zipCode')!.reset();
        this.accountForm.get('zipCode')!.markAsTouched();
    }

    get phoneInput(): AbstractControl<string> | null {
        return this.phoneForm ? this.phoneForm.get('phone') : null;
    }

    get countryCode(): AbstractControl<string> | null {
        return this.accountForm.get('countryCode');
    }

    get phone(): AbstractControl<string> | null {
        return this.accountForm.get('phone');
    }

    /**
     * @method onSubmit()
     * @description Submission action
     */

    onSubmit(): void {
        if (this.accountForm.valid && this.phoneForm.valid) {
            this._ngxSpinnerService.show();
            const url: string = `${this._authentication}/updateCustomer`;
            let params: any = {};

            Object.keys(this.accountForm.controls).forEach((name: string): void => {
                const currentControl = this.accountForm.controls[name];
                currentControl.dirty ? (params[name] = currentControl.value) : '';
            });
            if (
                this.initialCountryCode != this.phoneForm.get('code')?.value ||
                this.initialPhone != this.phoneForm.get('phone')?.value
            ) {
                params['countryCode'] = this.PhoneInputComponent.getCountryCode();
                params['phone'] = this.phoneForm.get('phone')?.value;
            }
            this._restService
                .post(url, { data: params })
                .then((response: any): void => {
                    if (response) {
                        Swal.fire({
                            title: 'Good job...',
                            text: 'Your account data was updated successfully',
                            icon: 'success',
                            confirmButtonText: 'Close',
                            showConfirmButton: true,
                            allowOutsideClick: false,
                        }).then((): void => {
                            this._customerService.saveCurrentUser(response.authorizationToken);
                            window.location.reload();
                        });
                    }
                })
                .catch((error: any): void => {
                    Swal.fire({
                        html: `${this._errorHandlerService.errorMsg(error.error)}`,
                        icon: 'error',
                        showConfirmButton: false,
                        showCancelButton: true,
                        cancelButtonText: 'Close',
                        allowOutsideClick: false,
                    });
                })
                .finally((): void => {
                    this._ngxSpinnerService.hide();
                });
        } else if (this.accountForm.invalid) {
            this.accountForm.markAllAsTouched();
            this.phoneForm.markAllAsTouched();
            return;
        }
    }

    /**
     * @method trackBy()
     * @param (index: number)
     * @param (item: any)
     * @description Compare the current object with the new one; takes the index and the current item as arguments and returns the unique identifier by which that item should be tracked
     */

    trackBy(index: number, item: any): string {
        return item.id;
    }
}
