import * as moment from 'moment/moment';
import { Facility } from 'src/app/models/payments/facility.model';
import { FormatPaymentReferencePipe } from 'src/app/pipes/format-payment-reference.pipe';
import { paymentMethod } from '../constants';
import { sortPaymentTypes } from '../ordered-data';

export class Dynamic {
    static readonly ReplacementFieldsDetails = ['nameFiles', 'awb', 'hawb', 'amount', 'eiReferenceFileNumber'];
    static readonly currentDate = moment().format('YYYY-MM-DD 00:00:00');
    static readonly paymentReferencePipe = new FormatPaymentReferencePipe();
    static readonly paymentTypesDynamic = Object.freeze(
        sortPaymentTypes([
            'Bill of Lading',
            'Breakdown fee',
            'Charter/ULD Bypass Fees',
            'Import Service Fee (ISC)',
            'Import Service + Storage',
            'Ocean Freight',
            'Pallets/Wood skids',
            'Storage/Demurrage/Detention',
            'Other',
        ])
    );

    /*
     * OPEN INVOICES
     */

    static readonly dynamic_ColumnsOpenInvoices = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerRef',
        'requesterEmail',
        'facilityReference',
        'location',
        'amount',
        'shortPaymentAmount',
        'balanceDue',
        'dueDate',
    ];
    static readonly dynamic_ColumnsNameOpenInvoices = [
        'select',
        'Invoice number',
        'Created date',
        'Customer Reference',
        'Requester email',
        'Facility reference',
        'Location',
        'Amount',
        'Short Payment Amount',
        'Balance due',
        'Due date',
    ];
    static readonly dynamic_getCompletedOpenInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        customerRef: item.customerReference ? item.customerReference : 'N/A',
        requesterEmail: item.paymentNotificationEmail
            ? item.paymentNotificationEmail
            : item.customer && item.customer.email
              ? item.customer.email
              : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        shortPaymentAmount: item.paymentShort ? item.paymentShort : 'N/A',
        balanceDue: item.amount
            ? '$' +
              (
                  Number.parseFloat(item.amount) -
                  (Number.parseFloat(item.shortPayment) ? Number.parseFloat(item.shortPayment) : 0)
              ).toFixed(2)
            : 'N/A',
        dueDate: item.dueDate ? moment.utc(item.dueDate).format('MMM DD, YYYY') : '',
        overDue:
            moment.utc(item.dueDate).startOf('day').diff(Dynamic.currentDate, 'days') >= 0 &&
            moment.utc(item.dueDate).startOf('day').diff(Dynamic.currentDate, 'days') < 7
                ? 'almostDue'
                : moment.utc(item.dueDate).startOf('day').diff(Dynamic.currentDate, 'days') < 0
                  ? 'overdue'
                  : 'onTime',
        id: item.id,
        location: item.location ? item.location.name : 'N/A',
        dynamicValues: item.dynamicValues ? item.dynamicValues : '',
        hasDownloadableContent: true,
        disabledCheckbox: item?.achDebitId,
    });
    static readonly dynamic_ColumnsSortableOpenInvoices = [
        'createdDate',
        'customerRef',
        'requesterEmail',
        'facilityReference',
        'location',
        'amount',
        'shortPaymentAmount',
        'balanceDue',
        'dueDate',
    ];

    /*
     * PAID INVOICES
     */

    static readonly dynamic_ColumnsPaidInvoices = [
        'select',
        'invoiceNumber',
        'createdDate',
        'customerRef',
        'facilityReference',
        'location',
        'amount',
        'paymentDate',
        'paymentMethod',
    ];
    static readonly dynamic_ColumnsNamePaidInvoices = [
        'select',
        'Invoice Number',
        'Created date',
        'Customer Reference',
        'Facility reference',
        'Location',
        'Amount',
        'Payment date',
        'Payment method',
    ];
    static readonly dynamic_getCompletedPaidInvoices = (item: any) => ({
        invoiceNumber: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerRef: item.customerReference ? item.customerReference : 'N/A',
        facilityReference: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentDate: item.paymentDate ? moment(item.paymentDate).format('MMM DD, YYYY') : '',
        paymentMethod: item.paymentMethod ? paymentMethod[item.paymentMethod.name] ?? item.paymentMethod.name : 'N/A',
        confirmationId: item.confirmationId,
        id: item.id,
        location: item.location ? item.location.name : 'N/A',
        hasDownloadableContent: true,
    });
    static readonly dynamic_ColumnsSortablePaidInvoices = [
        'createdDate',
        'customerRef',
        'facilityReference',
        'location',
        'amount',
        'paymentDate',
        'paymentMethod',
    ];

    /*
     * REJECTED PAYMENTS
     */

    static readonly dynamic_ColumnsRejectedPayment = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
        'nameFiles',
    ];
    static readonly dynamic_ColumnsNameRejectedPayment = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Customer Reference',
        'Amount',
        'Location',
        'Attachment found',
    ];
    static readonly dynamic_getCompletedRejectedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerRef: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        nameFiles: item.attachments ? ' Yes ' : 'N/A',
        dynamicValues: item.dynamicValues ? item.dynamicValues : '',
    });
    static readonly dynamic_ColumnsSortableRejectedPayment = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
    ];

    /*
     * VOIDED PAYMENTS
     */

    static readonly dynamic_ColumnsVoidedPayment = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
        'nameFiles',
    ];
    static readonly dynamic_ColumnsNameVoidedPayment = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Customer Reference',
        'Amount',
        'Location',
        'Attachment found',
    ];
    static readonly dynamic_getCompletedVoidedPayment = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerRef: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow.location.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        nameFiles: item.attachments ? ' Yes ' : 'N/A',
        dynamicValues: item.dynamicValues ? item.dynamicValues : '',
    });
    static readonly dynamic_ColumnsSortableVoidedPayment = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
    ];

    /*
     * HISTORY
     */

    static readonly dynamic_ColumnsHistory = [
        'select',
        'linkId',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
        'nameFiles',
        'facilityPaymentDeliveryNotificationShort',
    ];
    static readonly dynamic_ColumnsNameHistory = [
        'select',
        'Payment reference #',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Customer Reference',
        'Amount',
        'Location',
        'Attachment found',
        'Delivery type',
    ];
    static readonly dynamic_getCompletedPaymentsHistory = (item: any) => ({
        id: item.name ? item.name : 'N/A',
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : '',
        facilityName: item.facility ? item.facility.name : 'N/A',
        link: `../paymentDetail/${item.vendorInvoiceNumber ? item.vendorInvoiceNumber : item.id}`,
        awb: item.awb ? item.awb : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        location: item.location || 'N/A',
        customerRef: item.customerReference ? item.customerReference : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        paymentConfirmation: item.isPaymentConfirmationAvailable ? item.isPaymentConfirmationAvailable : '',
        externalPaymentConfirmation: item.isExternalPaymentConfirmationAvailable
            ? item.isExternalPaymentConfirmationAvailable
            : '',
        nameFiles: item.attachments ? ' Yes ' : 'N/A',
        dynamicValues: item.dynamicValues ? item.dynamicValues : '',
        attachments: item.attachments ? item.attachments : null,
        hasDownloadableContent: item.hasDownloadableContent ?? true,
        facilityPaymentDeliveryNotificationShort:
            item.facility && item.facility.facilityPaymentDeliveryNotificationShort
                ? item.facility.facilityPaymentDeliveryNotificationShort
                : 'N/A',
        linkId: Dynamic.paymentReferencePipe.transform(item.vendorInvoiceNumber, item.name),
    });
    static readonly dynamic_ColumnsSortableHistory = [
        'createdDate',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
    ];

    /*
     * PENDING APPROVAL
     */

    static readonly dynamic_ColumnsPendingApproval = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
        'approvalLevel',
        'nameFiles',
    ];
    static readonly dynamic_ColumnsNamePendingApproval = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Customer Reference',
        'Amount',
        'Location',
        'Status',
        'Attachment found',
    ];
    static readonly dynamic_getCompletedPaymentsPendingApproval: any = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerRef: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
        nameFiles: item.attachments ? ' Yes ' : 'N/A',
        dynamicValues: item.dynamicValues ? item.dynamicValues : '',
        approvalFlowLevel: item.approvalFlowLevel || '',
    });
    static readonly dynamic_ColumnsSortablePendingApproval = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
        'approvalLevel',
    ];

    /*
     * PENDING APPROVAL HOME COMPONENT
     */

    static readonly dynamic_ColumnsPendingApprovalHomeComponent: string[] = [
        'select',
        'id',
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
        'approvalLevel',
        'nameFiles',
    ];
    static readonly dynamic_ColumnsNamePendingApprovalHomeComponent: string[] = [
        'select',
        'Request#',
        'Date',
        'Cargo facility',
        'MAWB/Invoice#',
        'HAWB/Container#',
        'Customer Reference',
        'Amount',
        'Location',
        'Status',
        'Attachment found',
    ];
    static readonly dynamic_getCompletedPaymentsPendingApprovalHomeComponent: any = (item: any) => ({
        id: item.id ? item.id : '',
        link: `../facilityPayments/pendingApproval/paymentDetail/${item.id}/${item.approvalStatus}`,
        createdDate: item.createdDate ? moment(item.createdDate).format('MMM DD, YYYY') : 'N/A',
        customerRef: item.customerReference ? item.customerReference : 'N/A',
        awb: item.awb ? item.awb : 'N/A',
        amount: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
        facilityName: item.facility ? item.facility.name : 'N/A',
        location: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.location?.name : 'N/A',
        hawb: item.hawb ? item.hawb : 'N/A',
        approvalLevels: item.approvalFlowLevel ? item.approvalFlowLevel.approvalFlow?.approvalFlowLevels : '',
        approvalLevel: item.approvalFlowLevel
            ? item.approvalFlowLevel.approvalLevel?.name +
              ' of ' +
              item.approvalFlowLevel.approvalFlow?.approvalFlowLevels?.length +
              ' levels'
            : 'Added to cart by - ' + item.customer?.name,
        nameFiles: item.attachments ? ' Yes ' : 'N/A',
        dynamicValues: item.dynamicValues ? item.dynamicValues : '',
    });

    static readonly getPaymentDetailsPaymentSummary = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup
                ? 'File Number'
                : facility.awbFieldType === 'AWB'
                  ? 'RELATED BOL / AWB #'
                  : facility.awbFieldLabel ?? 'N/A',
            value: item.awb ? item.awb : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        {
            label: 'Amount',
            value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A',
            show: (!facility.externalLookup && !id) || id,
        },
        { label: 'HAWB/Container No.', value: item.hawb ? item.hawb : 'N/A', show: !facility.externalLookup },
    ];
    static readonly getCustomerReferencePaymentSummary = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : 'Customer Reference',
            value: item.customerRef ? item.customerRef : 'N/A',
            show: true,
        },
        { label: 'Payment Notification Email', value: item.email ? item.email : 'N/A', show: true },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly dynamic_getCompanyCustomerReferencePaymentSummary: any = (item: any) => ({
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });
    static readonly getPaymentDetailsPaymentHistory = (item: any, facility: Facility, id: string) => [
        {
            label: facility.externalLookup ? 'File Number' : 'Second facility reference #',
            value: item.hawb ? item.hawb : 'N/A',
            show: !facility.externalLookup && !id,
        },
        { label: 'Paid To', value: item.paidTo ? item.paidTo : 'N/A', show: true },
        { label: 'Cargo facility', value: facility.name ? facility.name : 'N/A', show: true },
        { label: 'Amount', value: item.amount ? '$' + Number.parseFloat(item.amount).toFixed(2) : 'N/A', show: true },
    ];
    static readonly getCustomerReferencePaymentHistory = (item: any, customerReferenceLabel: string = '') => [
        {
            label: customerReferenceLabel ? customerReferenceLabel : 'Customer Reference',
            value: item.customerReference ? item.customerReference : 'N/A',
            show: true,
        },
        {
            label: 'Payment Notification Email',
            value: item.notificationEmail ? item.notificationEmail : 'N/A',
            show: true,
        },
        { label: 'Notes', value: item.notes ? item.notes : 'N/A', show: true },
    ];
    static readonly dynamic_getCompanyCustomerReferencePaymentHistory: any = (item: any) => ({
        paymentAmount: item.paymentAmount || null,
        type: item.type ? item.type : null,
        locationName: item.locationName ? item.locationName : null,
    });

    static readonly dynamic_ColumnsSortablePendingApprovalHomeComponent = [
        'createdDate',
        'facilityName',
        'awb',
        'hawb',
        'customerRef',
        'amount',
        'location',
        'approvalLevel',
    ];

    paymentDetails = [
        {
            label: 'AWB',
            id: 'awb',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Paid To',
            id: 'paidTo',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Amount',
            id: 'amount',
            value: 'N/A',
            type: 'currency',
        },
        {
            label: 'HAWB/Container No. ',
            id: 'hawb',
            value: 'N/A',
            type: 'string',
        },
    ];
    customerReference = [
        {
            label: 'Customer Reference',
            id: 'customerRef',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Payment Notification Email',
            id: 'email',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Notes',
            id: 'notes',
            value: 'N/A',
            type: 'string',
        },
    ];
    dynamicCustomerReference = [
        {
            label: 'Payment amount',
            id: 'paymentAmount',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Type',
            id: 'type',
            value: 'N/A',
            type: 'string',
        },
        {
            label: 'Location',
            id: 'locationName',
            value: 'N/A',
            type: 'string',
        },
    ];
}
