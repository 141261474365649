import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

// ROUTING

//COMPONENTS

import { FormHeaderComponent } from 'src/app/standalone-components/form-header/form-header.component';
import { FormSignupComponent } from './form-signup.component';
import { StepOneComponent } from './step-one/step-one.component';
import { StepThreeComponent } from './step-three/step-three.component';

//MODULES
import {
    AddressInformationComponent,
    AddressValidationPromptComponent,
    GoogleAddressValidationService,
    PhoneInputComponent,
} from '@cargos/sprintpay-ui';
import { TwoFactorModule } from 'src/app/modules/two-factor/two-factor.module';
import { FormPasswordComponent } from 'src/app/standalone-components/form-password/form-password.component';
import { TermOfUseComponent } from 'src/app/standalone-components/term-of-use/term-of-use.component';
import { VerificationMethodComponent } from 'src/app/standalone-components/verification-method/verification-method.component';
import { SharedModule } from '../../../../shared/shared.module';
import { FormSignupRoutingModule } from './form-signup-routing.module';
import { StepTwoComponent } from './step-two/step-two.component';

@NgModule({
    declarations: [FormSignupComponent, StepOneComponent, StepTwoComponent, StepThreeComponent],
    providers: [GoogleAddressValidationService],
    imports: [
        CommonModule,
        FormSignupRoutingModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        SharedModule,
        TwoFactorModule,
        FormPasswordComponent,
        TermOfUseComponent,
        VerificationMethodComponent,
        FormHeaderComponent,
        PhoneInputComponent,
        AddressInformationComponent,
        AddressValidationPromptComponent,
    ],
})
export class FormSignupModule {
    constructor() {}
}
