import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpStatusCode,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, EMPTY, Observable, throwError } from 'rxjs';
import { catchError, delay, filter, finalize, switchMap, take } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { CustomerService } from '../services/utils/customer-handler.service';
import { TokenService } from '../services/utils/token.service';
import { UserSessionService } from '../services/utils/user-session.service';

export interface RetryRequestOptions {
    retryDelay: number;
    requestDelay: number;
    maxAttempts: number;
}
export interface CachedRequests {
    timestamp: number;
    request: HttpRequest<unknown>;
}
@Injectable()
export class HttpUnauthorizedInterceptor implements HttpInterceptor {
    private isRefreshingToken: boolean;
    private tokenSubject: BehaviorSubject<string | null>;
    private excludedUrls: string[];
    private cachedRequests: CachedRequests[] = [];
    // default retry options
    private retryRequestOptions: RetryRequestOptions = {
        retryDelay: 3000, //milliseconds
        requestDelay: 1000, //milliseconds
        maxAttempts: 3, //max number of retries
    };
    constructor(
        private tokenService: TokenService,
        private userSessionService: UserSessionService,
        private customerService: CustomerService,
        private router: Router
    ) {
        this.isRefreshingToken = false;
        this.tokenSubject = new BehaviorSubject<string | null>(null);
        this.excludedUrls = [environment.uris.method.authentication, 'api.ipify.org'];
    }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        return next.handle(request).pipe(
            catchError((error) => {
                return this.catchHttpError(error, request, next);
            })
        );
    }
    private catchHttpError(
        error: HttpErrorResponse,
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        if (
            error instanceof HttpErrorResponse &&
            [0, HttpStatusCode.Unauthorized].includes(error.status) &&
            !this.excludedUrls.some((url) => error.url?.includes(url))
        ) {
            this.cachedRequests.push({ timestamp: new Date().valueOf(), request: request });
            if (this.findRepeatedReqCount(request).length < this.retryRequestOptions.maxAttempts) {
                return this.handleUnauthorizedError(request, next);
            }
            const isGuest = this.customerService.isGuest();
            if (isGuest) {
                return this.signAsGuest();
            }
            this.userSessionService.logout();
            return throwError(() => new Error("'Unauthorized'"));
        }

        return throwError(() => error);
    }
    private signAsGuest(): Observable<HttpEvent<unknown>> {
        this.userSessionService.signInAsGuest().pipe(
            take(1),
            finalize(() => {
                this.router.navigate(['/']);
            })
        );
        return EMPTY;
    }
    private findRepeatedReqCount(request): CachedRequests[] {
        return this.cachedRequests.filter((httpRequest) => {
            return (
                httpRequest.request.url == request.url &&
                httpRequest.timestamp >= new Date().valueOf() - this.retryRequestOptions.retryDelay
            );
        });
    }

    private handleUnauthorizedError(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);

            return this.userSessionService.refreshToken().pipe(
                switchMap((authorizationToken) => {
                    if (authorizationToken) {
                        this.tokenSubject.next(authorizationToken);
                        return this.retryWithNewToken(request, next, authorizationToken);
                    } else {
                        throw Error('Unauthorized');
                    }
                }),
                delay(this.retryRequestOptions.requestDelay),
                catchError((error) => {
                    return this.catchHttpError(error, request, next);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                })
            );
        }
        return this.tokenSubject.pipe(
            filter((token) => !!token),
            take(1),
            switchMap((token) => {
                return this.retryWithNewToken(request, next, token!);
            }),
            delay(this.retryRequestOptions.requestDelay),
            catchError((error) => {
                return this.catchHttpError(error, request, next);
            })
        );
    }

    private retryWithNewToken(
        req: HttpRequest<unknown>,
        next: HttpHandler,
        newToken: string
    ): Observable<HttpEvent<unknown>> {
        const request = req.clone({
            setHeaders: {
                Authorization: `Bearer ${newToken}`,
            },
        });
        return next.handle(request);
    }
}
